import Loadable from 'components/Loadable';

const watchLists = {
  path: '/watch-list',
  component: Loadable({
    loader: () => import('../views/watch-list'),
  }),
  routes: [
    {
      exact: true,
      path: '/watch-list',
      component: Loadable({
        loader: () => {
          return import('../views/watch-list/list');
        },
      }),
    },
    // {
    //   path: '/watch-list/groups',
    //   component: Loadable({
    //     loader: () => import('../views/watch-list/groups'),
    //   }),
    //   routes: [
    //     {
    //       exact: true,
    //       path: '/watch-list/groups',
    //       component: Loadable({
    //         loader: () => import('../views/watch-list/groups/list'),
    //       }),
    //     },
    //     {
    //       path: '/watch-list/groups/:wlGroupId',
    //       component: Loadable({
    //         loader: () => import('../views/watch-list/groups/view'),
    //       }),
    //       routes: [
    //         {
    //           exact: true,
    //           path: '/watch-list/groups/:wlGroupId',
    //           component: (props) => {
    //             const {location} = props;
    //             const {pathname} = location || {};

    //             return <Redirect to={`${pathname}/certifications`} />;
    //           },
    //         },

    //         {
    //           exact: true,
    //           path: '/watch-list/groups/:wlGroupId/certifications',
    //           component: Loadable({
    //             loader: () =>
    //               import('../views/watch-list/groups/view/certifications'),
    //           }),
    //         },
    //         {
    //           exact: true,
    //           path: '/watch-list/groups/:wlGroupId/api-settings',
    //           component: Loadable({
    //             loader: () =>
    //               import('../views/watch-list/groups/view/api-settings'),
    //           }),
    //         },
    //       ],
    //     },
    //   ],
    // },

    // {
    //   exact: true,
    //   path: '/watch-list/api-settings',
    //   component: Loadable({
    //     loader: () => import('../views/watch-list/api-settings'),
    //   }),
    // },
  ],
};

export default watchLists;
