import AES from 'crypto-js/aes';
import encBase64 from 'crypto-js/enc-base64';
import encUtf8 from 'crypto-js/enc-utf8';
import {SECRET_KEY, IS_STAGE_DEV} from 'configs';

export function encrypt(value) {
  try {
    let encJson = AES.encrypt(JSON.stringify(value), SECRET_KEY).toString();
    let encData = encBase64.stringify(encUtf8.parse(encJson));

    return encData;
  } catch (err) {
    if (IS_STAGE_DEV) console.error(err);
    return undefined;
  }
}

export function decrypt(value) {
  if (!value) return undefined;

  try {
    let decData = encBase64.parse(value).toString(encUtf8);
    let bytes = AES.decrypt(decData, SECRET_KEY).toString(encUtf8);

    return JSON.parse(bytes);
  } catch (err) {
    if (IS_STAGE_DEV) console.error(err);
    return undefined;
  }
}
