import Loadable from 'components/Loadable';
import {PERMISSIONS} from 'configs/permissions';

const companyList = {
  path: '/company-list',
  permission: PERMISSIONS.COMPANY_LIST.ACCESS,
  component: Loadable({
    loader: () => import('../views/company-list'),
  }),
  routes: [
    // Company Lists
    {
      exact: true,
      path: '/company-list',
      component: Loadable({
        loader: () => import('../views/company-list/list'),
      }),
    },
    // {
    //   exact: true,
    //   path: '/company-list/api-settings',
    //   component: Loadable({
    //     loader: () => import('../views/company-list/api-settings'),
    //   }),
    // },
    {
      path: '/company-list/:companyId',
      component: Loadable({
        loader: () => import('../views/company-list/view/'),
      }),
      routes: [
        {
          exact: true,
          path: '/company-list/:companyId',
          component: Loadable({
            loader: () => import('../views/company-list/view/details'),
          }),
        },
        {
          path: '/company-list/:companyId/certifications',
          component: Loadable({
            loader: () => import('../views/company-list/view/certifications'),
          }),
          routes: [
            {
              exact: true,
              path: '/company-list/:companyId/certifications',
              component: Loadable({
                loader: () =>
                  import('../views/company-list/view/certifications/list'),
              }),
            },
            {
              exact: true,
              path: '/company-list/:companyId/certifications/:certId',
              component: Loadable({
                loader: () =>
                  import('../views/company-list/view/certifications/view'),
              }),
            },
          ],
        },
      ],
    },
  ],
};

export default companyList;
