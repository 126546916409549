import urljoin from 'url-join';

import Loadable from 'components/Loadable';
import {ROUTES_STANDARDS} from 'configs/routes';

const standards = {
  path: '/standards',
  component: Loadable({
    loader: () => import('../views/standards'),
  }),
  routes: [
    {
      path: urljoin('/standards', ROUTES_STANDARDS['iso-9001']),
      component: Loadable({
        loader: () => import('../views/standards/iso-9001'),
      }),
    },
    {
      path: urljoin('/standards', ROUTES_STANDARDS['iso-14001']),
      component: Loadable({
        loader: () => import('../views/standards/iso-14001'),
      }),
    },
    {
      path: urljoin('/standards', ROUTES_STANDARDS['iso-13485']),
      component: Loadable({
        loader: () => import('../views/standards/iso-13485'),
      }),
    },
    {
      path: urljoin('/standards', ROUTES_STANDARDS['iso-22000']),
      component: Loadable({
        loader: () => import('../views/standards/iso-22000'),
      }),
    },
    {
      path: urljoin('/standards', ROUTES_STANDARDS['iso-iec-27001']),
      component: Loadable({
        loader: () => import('../views/standards/iso-iec-27001'),
      }),
    },
    {
      path: urljoin('/standards', ROUTES_STANDARDS['iso-45001']),
      component: Loadable({
        loader: () => import('../views/standards/iso-45001'),
      }),
    },
    {
      path: urljoin('/standards', ROUTES_STANDARDS['iso-50001']),
      component: Loadable({
        loader: () => import('../views/standards/iso-50001'),
      }),
    },
  ],
};

export default standards;
