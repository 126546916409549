import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  createContext,
} from 'react';
import {
  Row,
  Col,
  Toast as ReactstrapToast,
  ToastBody as ReactToastBody,
} from 'reactstrap';
import {createId} from '@paralleldrive/cuid2';
import cx from 'classnames';
import {get, isObject, keys, isEmpty} from 'lodash';
import dayjs from 'dayjs';

import ERROR_MESSAGE from 'configs/ErrorMessages';
import {IconFA} from 'components/Icons';

const TOAST_POSITION = {
  'top-left': 'top-left',
  'top-right': 'top-right',
  'top-center': 'top-center',
  'bottom-left': 'bottom-left',
  'bottom-right': 'bottom-right',
  'bottom-center': 'bottom-center',
};

const TOAST_POSITION_COORDS = {
  'top-left': {top: '15%', left: '2%'},
  'top-right': {top: '15%', right: '2%'},
  'top-center': {top: '15%', left: '50%', transform: 'translateX(-50%)'},
  'bottom-left': {
    bottom: '10%',
    left: '2%',
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  'bottom-right': {
    bottom: '10%',
    right: '5',
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  'bottom-center': {
    bottom: '90%',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    flexDirection: 'column-reverse',
  },
};

function checkCustomPositionToast(object) {
  return (
    isObject(object) &&
    (object.hasOwnProperty('top') ||
      object.hasOwnProperty('right') ||
      object.hasOwnProperty('bottom') ||
      object.hasOwnProperty('left'))
  );
}

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

const ToastContext = createContext({});

export const useTimeout = (callback, delay) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay === null) return;

    const id = setTimeout(() => savedCallback.current(), delay);

    return () => clearTimeout(id);
  }, [delay]);
};

function Toast(props) {
  const {options, close, remove} = props;
  const {isOpen, icon, type, title, body, timeout, date, callback} = options;

  useTimeout(removeToast, timeout);

  async function removeToast() {
    close(); // close the toast
    if (callback) await callback();

    // this timeout is to account for fade animation
    await sleep(2000);
    remove(); // remove the toast to array
  }

  return (
    <ReactstrapToast isOpen={isOpen} className="toast-notification">
      <div
        className={cx('px-2 py-1 w-100', {
          [`bg-${type}`]: type,
          'text-white': type,
        })}
      >
        <Row noGutters className="justify-content-center">
          <Col className="col-auto">{icon && <IconFA name={icon} />}</Col>
          <Col className="flex-grow-1 mx-2">{title}</Col>
          <Col className="col-auto">
            {close && (
              <IconFA
                name="times"
                className="text-white"
                style={{cursor: 'pointer'}}
                onClick={() => close()}
              />
            )}
          </Col>
        </Row>
      </div>
      {(body || date) && (
        <ReactToastBody>
          {body}
          {date && (
            <div>
              <small>{dayjs().format('DD MMM YYYY, h:mm:ss [UTC]')}</small>
            </div>
          )}
        </ReactToastBody>
      )}
    </ReactstrapToast>
  );
}

function ToastProvider(props) {
  const {children} = props;

  const [toasts, setToasts] = useState([]);

  function show(toast) {
    setToasts((prevStates) => [
      ...prevStates,
      {id: createId(), isOpen: true, ...toast},
    ]);
  }

  function close(id) {
    const newToasts = [...toasts];
    const index = newToasts.findIndex((toast) => toast.id === id);

    newToasts[index] = {...newToasts[index], isOpen: false};
    setToasts(newToasts);
  }

  function remove(id) {
    setToasts((currentToasts) =>
      currentToasts.filter((toast) => toast.id !== id)
    );
  }

  const topLeftToast = toasts.filter(
    (toast) => toast.position === TOAST_POSITION['top-left']
  );
  const topCenterToast = toasts.filter(
    (toast) => toast.position === TOAST_POSITION['top-center']
  );
  const topRightToast = toasts.filter(
    (toast) => toast.position === TOAST_POSITION['top-right']
  );
  const bottomLeftToast = toasts.filter(
    (toast) => toast.position === TOAST_POSITION['bottom-left']
  );
  const bottomCenterToast = toasts.filter(
    (toast) => toast.position === TOAST_POSITION['bottom-center']
  );
  const bottomRightToast = toasts.filter(
    (toast) => toast.position === TOAST_POSITION['bottom-right']
  );
  const customPositionToast = toasts.filter((toast) => {
    return checkCustomPositionToast(toast.position);
  });

  const toastsCollection = [
    topLeftToast && {
      position: TOAST_POSITION['top-left'],
      toasts: topLeftToast,
    },
    topRightToast && {
      position: TOAST_POSITION['top-right'],
      toasts: topRightToast,
    },
    topCenterToast && {
      position: TOAST_POSITION['top-center'],
      toasts: topCenterToast,
    },
    bottomLeftToast && {
      position: TOAST_POSITION['bottom-left'],
      toasts: bottomLeftToast,
    },
    bottomRightToast && {
      position: TOAST_POSITION['bottom-right'],
      toasts: bottomRightToast,
    },
    bottomCenterToast && {
      position: TOAST_POSITION['bottom-center'],
      toasts: bottomCenterToast,
    },
    customPositionToast && {
      position: (customPositionToast[0] || {}).position,
      toasts: customPositionToast,
    },
  ].filter((v) => !!v);

  return (
    <ToastContext.Provider value={{show, close, remove}}>
      {toastsCollection.map((collection, index) => {
        const {position, toasts} = collection;

        if (isEmpty(toasts)) return null;

        if (checkCustomPositionToast(position)) {
          return (
            <div
              key={index}
              style={{zIndex: '200', position: 'fixed', ...position}}
            >
              {toasts.map((toastOptions) => {
                const {id} = toastOptions;

                return (
                  <Toast
                    key={id}
                    close={() => close(id)}
                    remove={() => remove(id)}
                    options={toastOptions}
                  />
                );
              })}
            </div>
          );
        }

        return (
          <div
            key={index}
            style={{
              zIndex: '200',
              position: 'fixed',
              ...TOAST_POSITION_COORDS[position],
            }}
          >
            {toasts.map((toastOptions) => {
              const {id} = toastOptions;

              return (
                <Toast
                  key={id}
                  close={() => close(id)}
                  remove={() => remove(id)}
                  options={toastOptions}
                />
              );
            })}
          </div>
        );
      })}

      {children}
    </ToastContext.Provider>
  );
}

const useToast = () => {
  const {show} = useContext(ToastContext);

  const toastSuccess = (options) => {
    show({
      type: 'success',
      icon: 'check-circle',
      title: 'Success',
      body: 'Success',
      date: true,
      timeout: 5000,
      position: TOAST_POSITION['top-center'],
      ...options,
    });
  };

  const toastError = (options) => {
    show({
      type: 'danger',
      icon: 'times',
      title: 'Error',
      body: 'Error',
      date: true,
      timeout: 5000,
      position: TOAST_POSITION['top-center'],
      ...options,
    });
  };

  const toastWarning = (options) => {
    show({
      type: 'warning',
      title: 'Warning',
      icon: 'exclamation-triangle',
      message: 'Warning',
      date: true,
      timeout: 5000,
      position: TOAST_POSITION['top-center'],
      ...options,
    });
  };

  const toastByError = (error, options) => {
    const {code, message} = get(error, 'errors', {});
    let text = '';
    if (isObject(message)) {
      // Multiple messages
      keys(message).forEach((key) => {
        if (text) text += '\n';
        text += message[key];
      });
    } else {
      if (ERROR_MESSAGE[code]) {
        text = ERROR_MESSAGE[code];
      } else {
        if (message) {
          text = message;
        } else {
          text = ERROR_MESSAGE.not_found;
        }
      }
    }

    show({
      title: 'Error',
      icon: 'xmark',
      message: text,
      date: true,
      position: TOAST_POSITION['top-center'],
      ...options,
    });
  };

  return {toastSuccess, toastError, toastWarning, toastByError};
};

function withToast(Component) {
  return function (props) {
    const toast = useToast();
    return <Component {...props} toast={toast} />;
  };
}

export {ToastContext, ToastProvider, useToast, withToast};
