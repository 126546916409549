import React, {useContext} from 'react';
// import {CookieConsent} from 'components/CookieConsent';
import CookieConsent from 'components/CookieConsent/CookieConsent';
import useCookieConsent from 'components/CookieConsent/useCookieConsent';

const ConsentContext = React.createContext({});

function ConsentProvider(props) {
  const {children} = props;
  const result = useCookieConsent();
  const {showBanner, consents} = result || {};

  return (
    <ConsentContext.Provider value={{consentBanner: showBanner, consents}}>
      {children}
      <CookieConsent {...result} />
    </ConsentContext.Provider>
  );
}

const useConsent = () => useContext(ConsentContext);

export {ConsentContext, ConsentProvider, useConsent};
