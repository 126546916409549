import React from 'react';
import urljoin from 'url-join';

import {useHistory} from 'react-router-dom';
import {CDN_IMAGE_URL} from 'configs';
import {Button, Col, Modal, Row} from 'reactstrap';
import {useSubscription} from 'contexts/SubscriptionContext';
import {useMembership} from 'contexts/MembershipContext';

const LOCKED_FEATURE_IMG = {
  verification: urljoin(
    CDN_IMAGE_URL,
    'vu/banners/free-user-certifications1.jpg'
  ),
  import: urljoin(CDN_IMAGE_URL, 'vu/banners/free-user-companies1.jpg'),
  watchlist: urljoin(CDN_IMAGE_URL, 'vu/banners/free-user-watchlist.jpg'),
  users: urljoin(CDN_IMAGE_URL, 'vu/banners/add-user-restricted.png'),
};

function LockedFeatureContent(props) {
  const {data, toggle, onUpgrade, persist} = props;
  const {title, subtitle, content} = data || {};

  return (
    <div className="d-flex flex-column justify-content-center align-items-start h-100 p-4">
      <h4 className="text-primary font-weight-bold nowrap mb-3">{title}</h4>
      {subtitle && <h6 className="font-weight-bold">{subtitle}</h6>}
      <p className="mb-4">{content}</p>
      <Button onClick={onUpgrade} color="primary" className="w-100 my-1">
        Upgrade Plan
      </Button>
      <Button
        onClick={toggle}
        color="white"
        className="w-100 text-primary my-1"
      >
        {persist ? 'Go back to dashboard' : 'Close'}
      </Button>
    </div>
  );
}

const LOCKED_FEATURE_CONTENT = {
  import: {
    title: 'Bulk Matching',
    subtitle: 'Verify certifications in bulk!',
    content:
      'Eliminate the need for manual searches by uploading an Excel file to automatically verify the certifications of over 10,000 suppliers across your entire supply chain. This efficient process saves time and reduces supply chain risk.',
  },
  verification: {
    title: 'Certification Verification',
    subtitle: 'History of searches',
    content:
      'All your certificate verifications are clearly listed for easy review, with the option to monitor specific certifications by adding them to a watchlist. Where you can receive real-time alerts if the status changes, such as when a certification is suspended, withdrawn, or expired.',
  },
  watchlist: {
    title: 'Certification Watchlist',
    subtitle: 'Monitor certifications',
    content:
      'Stay informed in real-time about any changes to the status of certifications in your watchlist, including suspensions, withdrawals, or expirations. This capability provides full transparency across your supply chain, enabling you to take proactive measures and minimize risk effectively.',
  },
  users: {
    title: 'Add Users',
    content:
      'Enhance productivity by adding more users, streamlining collaboration, and ensuring your entire supply chain meets global standards. With additional users, you can efficiently track compliance, manage certifications, and reduce risk across all suppliers.',
  },
};

function LockedFeatureModal(props) {
  const {isOpen, toggle, redirect, type, persist} = props;
  const {onUpgrade} = useSubscription();
  const {isTrial} = useMembership();
  const history = useHistory();

  function handleClick() {
    toggle(type);
    if (isTrial) {
      onUpgrade();
    } else {
      history.push(`/membership/plans?redirect=${redirect}`);
    }
  }

  function handleToggle() {
    if (persist) {
      toggle(type);
      return history.push('/dashboard');
    }
    return toggle(type);
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size="xl" backdrop="static">
      <Row noGutters>
        <Col className="col-12 col-xl-8">
          <img
            src={LOCKED_FEATURE_IMG[type]}
            alt="banner"
            height="100%"
            width="100%"
          />
        </Col>
        <Col className="col-12 col-xl-4">
          <LockedFeatureContent
            data={LOCKED_FEATURE_CONTENT[type]}
            onUpgrade={handleClick}
            toggle={handleToggle}
            persist={persist}
          />
        </Col>
      </Row>
    </Modal>
  );
}

export default LockedFeatureModal;
