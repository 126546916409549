import Loadable from 'components/Loadable';
import {PERMISSIONS} from 'configs/permissions';

const certificationsList = {
  path: '/certifications-list',
  permission: PERMISSIONS.COMPANY_LIST.ACCESS,
  component: Loadable({
    loader: () => import('../views/certifications-list'),
  }),
  routes: [
    {
      exact: true,
      path: '/certifications-list',
      component: Loadable({
        loader: () => import('../views/certifications-list/list'),
      }),
    },
    // {
    //   path: '/certifications/:certId',
    //   component: Loadable({
    //     loader: () => import('../views/certifications/view'),
    //   }),
    //   routes: [
    //     {
    //       exact: true,
    //       path: '/certifications/:certId',
    //       component: Loadable({
    //         loader: () => import('../views/certifications/view/details'),
    //       }),
    //     },
    //   ],
    // },
  ],
};

export default certificationsList;
