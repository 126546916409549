import React, {createContext, useContext} from 'react';
import {get} from 'lodash';
import {useAuth} from './AuthContext';

const MembershipContext = createContext({});

function MembershipProvider(props) {
  const {children} = props;

  const {account} = useAuth();

  const prevMeta = get(account, 'company.subscription.subscription_plans_meta');
  let subscriptionMeta = get(
    account,
    'company.subscription.subscriptions_meta'
  );

  const billingMethod = get(
    account,
    'company.subscription.subscriptions_billing_method'
  );

  if (!!prevMeta && typeof prevMeta === 'string') {
    subscriptionMeta = JSON.parse(prevMeta);
  }

  const permissions = get(subscriptionMeta, 'features');

  function hasPermit(pathPermit) {
    return get(permissions, pathPermit);
  }

  function getMetaValue(pathMeta) {
    return get(subscriptionMeta, pathMeta);
  }

  const isTrial = get(account, 'company.subscription.is_trial');
  const isTrialDone = get(account, 'company.subscription.trial_done');
  const subscriptionLevel = get(subscriptionMeta, 'level');

  const isSubscribed = subscriptionLevel > 0 && !isTrial;

  return (
    <MembershipContext.Provider
      value={{
        hasPermit,
        getMetaValue,
        permissions,
        isSubscribed,
        subscriptionLevel,
        subscriptionMeta,
        billingMethod,
        isTrial,
        isTrialDone,
      }}
    >
      {children}
    </MembershipContext.Provider>
  );
}

const useMembership = () => useContext(MembershipContext);

export {MembershipContext, MembershipProvider, useMembership};
