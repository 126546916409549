import React, {createContext, useContext, useState} from 'react';
import {get, isEmpty, omit, trimStart} from 'lodash';
import {useAuth} from './AuthContext';
import {
  SUBSCRIPTION_PLANS_TYPE,
  SUBSCRIPTION_STATUS,
} from 'configs/subscription';
import urljoin from 'url-join';
import {useHistory, useLocation} from 'react-router';
import useQuery from 'hooks/useQuery';
import {alertByError} from 'utils/alerts';
import {postSubscribeTrialToBasic} from 'api/subscriptions';
import useGetPriceBreakdown from 'modules/pricing/useGetPriceBreakdown';
import PricingBreakdownModal from 'modules/pricing/PricingBreakdownModal';
import useManageSubscription from 'modules/membership/useManageSubscription';
import PricingPaymentFailedModal from 'modules/pricing/PricingPaymentFailedModal';
import {useMembership} from './MembershipContext';

const SubscriptionContext = createContext();

function SubscriptionProvider(props) {
  const {children} = props;
  const {query} = useQuery();
  const {pathname} = useLocation();
  const {isTrialDone, subscriptionLevel} = useMembership();
  const [isLoading, setIsLoading] = useState(false);
  const {account, handleLoginBySession, getSession} = useAuth();
  const [openBreakdownModal, setOpenBreakdownModal] = useState(false);
  const [openPaymentFailedModal, setOpenPaymentFailedModal] = useState(false);

  const {redirect = 'dashboard'} = query || {};
  const history = useHistory();

  const {priceBreakdown, priceBreakdownLoading, getPriceBreakdown} =
    useGetPriceBreakdown();
  const {onManage, isLoading: isManageLoading} = useManageSubscription();

  const toggleBreakdownModal = () => setOpenBreakdownModal((prev) => !prev);
  const togglePaymentFailedModal = () =>
    setOpenPaymentFailedModal((prev) => !prev);

  const isFree =
    get(account, 'company.subscription.stripe_status') ===
    SUBSCRIPTION_STATUS.free;

  const isSubscribed =
    get(account, 'company.subscription.stripe_status') ===
    SUBSCRIPTION_STATUS.active;

  const isFreeTrial =
    get(account, 'company.subscription.stripe_status') ===
    SUBSCRIPTION_STATUS.trialing;

  const isSubscriptionCanceled =
    get(account, 'company.subscription.stripe_status') ===
    SUBSCRIPTION_STATUS.canceled;

  const subscriptionPlan = get(
    account,
    'company.subscription.subscription_plans_type'
  );

  const subscriptionPlanMeta = get(
    account,
    'company.subscription.subscription_plans_meta'
  );

  async function onSubscribe() {
    setIsLoading(true);

    const urlValues = `?success=true&redirect=${redirect}&previous=free_trial&current=${SUBSCRIPTION_PLANS_TYPE.basic}`;
    const successUrl = urljoin('/membership/plans', urlValues);

    try {
      const {data} = await postSubscribeTrialToBasic();

      if (data) {
        toggleBreakdownModal();
        handleContinue(successUrl);
      }
    } catch (e) {
      const {code} = e || {};

      if (code === 'incomplete_payment' || code === 'card_was_declined') {
        togglePaymentFailedModal();
      } else {
        alertByError(e);
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function onUpgrade() {
    if (!subscriptionLevel && !isTrialDone) {
      const prevParams = omit(query, 'subscription');
      const paramsURL = new URLSearchParams({
        ...prevParams,
        subscription: 'free_trial',
      });

      history.push(`/membership/plans${!!paramsURL && `?${paramsURL}`}`);
      return;
    }

    if (!isFreeTrial) {
      history.push(`/membership/plans?redirect=${trimStart(pathname, '/')}`);
      return;
    }

    toggleBreakdownModal();
    await getPriceBreakdown(SUBSCRIPTION_PLANS_TYPE.basic);
    return false;
  }

  async function handleContinue(url) {
    const {token} = getSession();
    const data = await handleLoginBySession(token);

    if (!isEmpty(data)) {
      history.push(url);
    }
  }

  return (
    <SubscriptionContext.Provider
      value={{
        isFree,
        onUpgrade,
        isSubscribed,
        isFreeTrial,
        isTrialDone,
        isSubscriptionCanceled,
        subscriptionPlan,
        subscriptionPlanMeta,
        subscriptionUpgradeBreakdown: priceBreakdown,
      }}
    >
      {children}

      <PricingBreakdownModal
        isSubscribing={isLoading}
        priceBreakdown={priceBreakdown}
        openBreakdownModal={openBreakdownModal}
        priceBreakdownLoading={priceBreakdownLoading}
        onUpgrade={onSubscribe}
        toggleBreakdownModal={toggleBreakdownModal}
      />

      <PricingPaymentFailedModal
        onSubmit={onManage}
        isLoading={isManageLoading}
        isOpen={openPaymentFailedModal}
        toggle={togglePaymentFailedModal}
      />
    </SubscriptionContext.Provider>
  );
}

const useSubscription = () => useContext(SubscriptionContext);

export {SubscriptionContext, SubscriptionProvider, useSubscription};
