// https://www.npmjs.com/package/react-use-hotjar

import React, {createContext, useContext, useEffect} from 'react';

import {HEATMAP_ID} from 'configs/Environment';
import useHotjar from 'react-use-hotjar';
import {useConsent} from 'contexts/ConsentContext';

const HeatmapContext = createContext({});

function HeatmapProvider(props) {
  const {children} = props;
  const {consents} = useConsent();
  const {initHotjar} = useHotjar(); // identifyHotjar for logging data to hotjar

  const {statistic} = consents || {};

  useEffect(() => {
    if (statistic) initHotjar(HEATMAP_ID, 6, false, console.log);
  }, [statistic]);

  return (
    <HeatmapContext.Provider value={{}}>{children}</HeatmapContext.Provider>
  );
}

const useHeatmap = () => useContext(HeatmapContext);

export {HeatmapContext, HeatmapProvider, useHeatmap};
