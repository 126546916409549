import Loadable from 'components/Loadable';

const freeTrial = {
  path: '/free-trial',
  component: Loadable({
    loader: () => import('../views/promotions/free-trial'),
  }),
  routes: [
    {
      exact: true,
      path: '/free-trial',
      component: Loadable({
        loader: () => import('../views/promotions/free-trial/landing'),
      }),
    },
    {
      path: [
        '/free-trial/verify/:accountId',
        '/free-trial/verified/:accountId',
      ],
      component: Loadable({
        loader: () => import('../views/promotions/free-trial/verify'),
      }),
    },
  ],
};

export default freeTrial;
