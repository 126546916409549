import {createContext, useContext, useEffect, useState} from 'react';

import urljoin from 'url-join';
import LocalStorage from 'utils/localStorage';

import {CDN_IMAGE_URL} from 'configs';
import {useAuth} from './AuthContext';
import {useHistory} from 'react-router';
import {alertByError} from 'utils/alerts';
import {getAvailableLimit} from 'api/membership';
import {useMembership} from './MembershipContext';

import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap';

const LimitsContext = createContext({});
const storedData = LocalStorage.showCreditsWarning;

function LimitWarningModal() {
  const history = useHistory();
  const {toggleWarningModal, isOpenWarning} = useLimits();

  function handleRedirect(path) {
    toggleWarningModal();
    history.push(path);
  }

  return (
    <Modal isOpen={isOpenWarning && storedData === '1'} centered>
      <div className="position-absolute" style={{right: 20, top: 10}}>
        <h4
          className="text-secondary"
          style={{cursor: 'pointer'}}
          onClick={toggleWarningModal}
        >
          &times;
        </h4>
      </div>
      <div>
        <img
          alt="Verification Credits Running Low!"
          src={urljoin(CDN_IMAGE_URL, 'vu/low-credits.jpg')}
          width="100%"
          height="100%"
        />
      </div>
      <ModalBody className="d-flex flex-column justify-content-center align-items-center p-4">
        <h4 className="font-weight-bold">Verification Credits Running Low!</h4>
        <p className="mb-0">
          Your certificate verification credits are running low. Upgrade your
          plan to ensure you can verify the certifications your organisation
          needs and stay updated on any changes to certification included in
          your watchlist, including suspensions, withdrawals, or expirations.
        </p>
      </ModalBody>
      <ModalFooter>
        <div className="flex-grow-1" onClick={toggleWarningModal}>
          <Button color="white" className="text-secondary">
            Skip
          </Button>
        </div>
        <Button
          color="outline-primary"
          onClick={() => handleRedirect('/watch-list')}
        >
          Manage Watchlist
        </Button>
        <Button
          color="primary"
          onClick={() => handleRedirect('/membership/plans')}
        >
          Upgrade Now
        </Button>
      </ModalFooter>
    </Modal>
  );
}

function LimitsProvider(props) {
  const {children} = props;
  const {isAuth, isFetchingSession} = useAuth();
  const {subscriptionLevel, isSubscribed} = useMembership();

  const [data, setData] = useState({});
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    available_limit_left: limitLeft,
    expiring_watchlist_items: expiringWatchlist,
  } = data || {};

  async function getLimits() {
    // Do not call if the user is not authenticated
    if (!isAuth) return false;

    setIsLoading(true);
    try {
      const {data} = await getAvailableLimit();
      setData({...data});
    } catch (e) {
      alertByError(e);
    } finally {
      setIsLoading(false);
    }
  }

  function toggleWarningModal() {
    LocalStorage.showCreditsWarning = '0'; // to not persist warning
    setIsOpenWarning((prev) => !prev);
  }

  useEffect(() => {
    if (isAuth && subscriptionLevel <= 10) {
      getLimits();
    }
  }, [isAuth]);

  useEffect(() => {
    const watchlistLimitReached = expiringWatchlist > limitLeft;

    // if session update finished, not premium or enterprise
    const showWarning = !isFetchingSession && watchlistLimitReached;

    if (
      showWarning &&
      subscriptionLevel <= 10 &&
      isSubscribed &&
      limitLeft >= 0
    ) {
      setIsOpenWarning((prev) => !prev);
    }
  }, [data]);

  return (
    <LimitsContext.Provider
      value={{data, isLoading, toggleWarningModal, isOpenWarning, getLimits}}
    >
      {children}
      <LimitWarningModal />
    </LimitsContext.Provider>
  );
}

const useLimits = () => useContext(LimitsContext);

export {LimitsProvider, LimitsContext, useLimits};
