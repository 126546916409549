import numeral from 'numeral';
import {SUBSCRIPTION_CURRENCY} from 'configs/subscription';
import {toLower} from 'lodash';

export function getFormattedNumber(value, formatter = '0,0') {
  // 3500 will be displayed "3,500" if in U.S. English locale
  return numeral(value).format(formatter);
}

export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

  if (i === 0) return `${bytes} ${sizes[i]})`;

  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export function getFormattedPrice(value, currency, format = '0,0.00') {
  return `${
    SUBSCRIPTION_CURRENCY[toLower(currency) || 'usd']
  }${getFormattedNumber(value, format)}`;
}

export function formatNumber(num) {
  if (num >= 1_000_000) {
    return `${Math.floor(num / 1_000_000)}m+`;
  } else if (num >= 1_000) {
    return `${Math.floor(num / 1_000)}k+`;
  } else {
    return num.toString();
  }
}
