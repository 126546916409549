import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';

import {
  IS_STAGE_DEV,
  ROUTE_ACCESS_RULES,
  ROUTE_ENV_RULES,
  COMPANY_STATUSES,
} from 'configs';
import {AuthConsumer} from 'contexts/AuthContext';
import {IAF_DC_URL} from 'configs/Environment';
import {useMembership} from 'contexts/MembershipContext';
import {get} from 'lodash';

const PublicRoute = ({env, path, routes, component: Component, ...rest}) => {
  const isDev = env === ROUTE_ENV_RULES.development;
  const isBlocked = isDev && !IS_STAGE_DEV;

  return (
    <Route
      {...rest}
      path={path}
      render={(props) => {
        // Check Environment
        if (isBlocked) return <Redirect to="/coming" />;

        return <Component {...props} routes={routes} />;
      }}
    />
  );
};

const DCAccount = () => {
  window.location.replace(IAF_DC_URL);

  return null;
};

const PrivateRoute = ({
  env,
  isAuth,
  isConsented,
  isEmailConfirmed,
  path,
  routes,
  component: Component,
  isDCAccount,
  permission,
  isStatusRestricted,
  ...rest
}) => {
  const isDev = env === ROUTE_ENV_RULES.development;
  const isBlocked = isDev && !IS_STAGE_DEV;
  const {hasPermit} = useMembership();

  if (isDCAccount) return <DCAccount />;

  return (
    <Route
      {...rest}
      render={(props) => {
        // 1.  Check Authentication
        if (!isAuth) {
          return <Redirect to="/" />;
        }

        const isNotPageException = ['/setup', '/consents', '/logout'].every(
          (p) => props.match.url !== p
        );

        // 2.  Check Consents
        if (!isConsented && isNotPageException) {
          return <Redirect to="/consents" />;
        }

        // 3. Check Email Confirmation
        if (!isEmailConfirmed && isNotPageException) {
          return <Redirect to="/setup" />;
        }

        // 4. Check Permission
        if (permission) {
          // if undefined its okay not to redirect but false should be main checker if it has no permission
          // sample of this is settings it should return undefined on AB but on CB it depends on the permission
          if (hasPermit(permission) === false) {
            return <Redirect to="/dashboard" />;
          }
        }

        if (isStatusRestricted) {
          return <Redirect to="/error/403" />;
        }

        // Check Environment
        if (isBlocked) return <Redirect to="/coming" />;

        return <Component {...props} routes={routes} />;
      }}
    />
  );
};

const GuestRoute = ({env, isAuth, routes, component: Component, ...rest}) => {
  const isDev = env === ROUTE_ENV_RULES.development;
  const isBlocked = isDev && !IS_STAGE_DEV;

  return (
    <Route
      {...rest}
      render={(props) => {
        // 1. Check Authentication
        if (isAuth) {
          return <Redirect to="/dashboard" />;
        }

        // Check Environment
        if (isBlocked) return <Redirect to="/coming" />;

        return <Component {...props} routes={routes} />;
      }}
    />
  );
};

const renderRoutes = (routes) => {
  // A <Switch> renders the first child <Route> that matches. A <Route> with no path always matches.
  return (
    <AuthConsumer>
      {({account, isAuth, isDCAccount, isConsented}) => {
        return (
          // Filter routes by access rules
          <Switch>
            {routes.map(({env, access, suspendedAccessible, ...rest}, i) => {
              switch (access) {
                // Authenticated User Only
                case ROUTE_ACCESS_RULES.private: {
                  const isCompanySuspended =
                    get(account, 'company.company_status') ===
                    COMPANY_STATUSES.suspended;
                  const isStatusRestricted =
                    isCompanySuspended && suspendedAccessible === true;

                  return (
                    <PrivateRoute
                      key={i}
                      env={env}
                      isEmailConfirmed={
                        isAuth && account.account_confirm_email === 1
                      }
                      isAuth={isAuth}
                      isConsented={isConsented}
                      isDCAccount={isDCAccount}
                      isStatusRestricted={isStatusRestricted}
                      {...rest}
                    />
                  );
                }

                // Unauthenticated User Only
                case ROUTE_ACCESS_RULES.guest: {
                  return (
                    <GuestRoute key={i} env={env} isAuth={isAuth} {...rest} />
                  );
                }

                // Open to Everyone
                default:
                case ROUTE_ACCESS_RULES.public: {
                  return <PublicRoute key={i} env={env} {...rest} />;
                }
              }
            })}

            <Route render={() => <Redirect to="/not-found" />} />
          </Switch>
        );
      }}
    </AuthConsumer>
  );
};

export default renderRoutes;
