import Loadable from 'components/Loadable';

const apiSettings = {
  exact: true,
  path: '/api-verification',
  component: Loadable({
    loader: () => import('../views/api-settings'),
  }),
};

export default apiSettings;
