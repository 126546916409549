import Loadable from 'components/Loadable';

const search = {
  path: '/search',
  component: Loadable({
    loader: () => import('../views/search'),
  }),
  routes: [
    {
      exact: true,
      path: '/search/certifications',
      component: Loadable({
        loader: () => import('../views/search/certifications'),
      }),
    },
    {
      exact: true,
      path: '/search/accreditation-bodies',
      component: Loadable({
        loader: () => import('../views/search/accreditation-bodies'),
      }),
    },
    {
      exact: true,
      path: '/search/certification-bodies/:country?',
      component: Loadable({
        loader: () => import('../views/search/certification-bodies'),
      }),
    },
  ],
};

export default search;
